@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* style for import button at import students page */
.import-label {
  padding: 10px;
  background-color: #4cceac;
  font-weight: bold;
  color: black;
  border-radius: 5px;
}

.category-list {
  list-style: none;
  padding: 0;
}

.category-item {
  padding: 10px;
  margin: 5px;
  background-color: #4cceac;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.category-item:hover {
  background-color: #ddd;
  color: #333;
}

/* Hide sidebar on screens smaller than 960px */
@media (max-width: 960px) {
  .app.show-sidebar .normal-sidebar {
    display: none;
  }
}

/* Style the toggle button */
.toggle-menu-button {
  display: none; /* Hide the button on larger screens */
}

/* Show the toggle button on smaller screens */
@media (max-width: 960px) {
  .toggle-menu-button {
    display: block;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 999;
  }
}

/* Style the edi button */
.edit-button {
  border: none;
  border-radius: 5px;
  padding: 3px;
  background-color: #4cceac;
  cursor: pointer;
}
